<template>
  <div>
      <div class="btnBox">
         <!-- <el-button type="primary" class="mb10" size="mini" @click="exportExcel">导出数据</el-button> -->
      </div>
    <div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn  label="店铺名称" prop="shop_name" />
            <ElTableColumn v-if="type == 'enter'" label="进店保留客户姓名" prop="customer_name" />
            <ElTableColumn v-if="type == 'deal'" label="成交保留客户姓名" prop="customer_name" />
            <ElTableColumn  label="是否保有" prop="status_desc"/>
            
          </el-table>
          <pagination
            v-if="type == 'enter'"
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList1"
          />
          <pagination
          v-else
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList2"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import pagination from '@/components/Pagination'
import { EnterDetails,DealDetails } from "@/api/statistics";
import { handleExportExcel } from "@/vendor/handleExportExcel";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  name: 'Bargain',
   components: {
    pagination
  },
  data () {
    return {
      list: [], // 数据展示
      page: new Page(), // 分页
      type:''
    }
  },
  mounted () {
    this.type = this.$route.query.type
    if(this.$route.query.type == 'enter'){
      this.getList1();
    }else{
      this.getList2();
    }
  },
  methods: {
    // 导出表格
    async exportExcel() {
      const { data } = await OrderStatistics({
         ...this.search
      });
      handleExportExcel(
        [
          '进店保留客户姓名',
          '进店客户姓名',
          '成交保留客户姓名',
          '成交客户姓名',
        ],
        [
          'customer_name',
          'customer_name',
          'customer_name',
          'customer_name',
        ],
        data.list,
        '进店成交明细详情'
      );
    },
    getList1() {
      EnterDetails({ ...this.page,shop_name:this.$route.query.shop_name,uid:this.$route.query.uid,old_enter_store_ids:this.$route.query.old_enter_store_ids, }).then(res => {
        this.list = res.data.list;
        this.list.forEach((val) => {
          val['shop_name'] = res.data.shop_name;
        });
        this.page.total = res.data.dataCount;
      });
    },
    getList2() {
      DealDetails({ ...this.page,shop_name:this.$route.query.shop_name,old_deal_ids:this.$route.query.old_deal_ids,deal_uid:this.$route.query.deal_uid, }).then(res => {
        this.list = res.data.list;
        this.list.forEach((val) => {
          val['shop_name'] = res.data.shop_name;
        });
        this.page.total = res.data.dataCount;
      });
    },

  }
}
</script>

<style lang="scss" scoped>
.reveal{
  text-align: right;
  padding: 20px 0;
  .peril{
    font-weight: bold;
  }
}
.btnBox{
  text-align: right;
}
</style>
